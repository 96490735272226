import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { server } from '@/store/server'
import { router } from '@/router'
import { Toasts } from '@/store'
import { ToastVariants } from '@/models/toasts.models'

import { UserData } from '@/models/user.models'

@Module({ name: 'UserModule' })
export class UserModule extends VuexModule {
	user = {} as UserData
	loggedIn = false

	@Action
	async logIn(userInfo: { username: string; password: string }) {
		server
			.post('jwt-auth/v1/token', {
				username: userInfo.username,
				password: userInfo.password,
			})
			.then((response) => this.LOG_IN(response.data))
			.catch((error) => Toasts.newToast({ message: error.response.data.message, variant: ToastVariants.warning }))
	}

	@Mutation
	private LOG_IN(userData: UserData) {
		this.user = userData
		this.loggedIn = true
		console.log(`Welcome, ${userData.user_display_name}!`)
		router.push({ name: 'Catalog' })
	}

	@Action
	async logOut() {
		this.LOG_OUT()
	}

	@Mutation
	private LOG_OUT() {
		this.loggedIn = false
		router.push({ name: 'LogIn' }).then(() => {
			this.user = {} as UserData
			window.localStorage.removeItem('vuex')
			console.log('[App] Log out')
		})
	}

	get admin() {
		return this.user.roles.includes('administrator')
	}

	get jeweler() {
		return this.user.roles.includes('jeweler')
	}

	get manager() {
		return this.user.roles.includes('manager')
	}
}
