export function numberToRUB(value: number) {
	return new Intl.NumberFormat('ru-RU', {
		style: 'currency',
		currency: 'RUB',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(value)
}

export function numberToDays(value: number) {
	return (
		new Intl.NumberFormat('ru-RU', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 1,
		}).format(value) + ' д'
	)
}

export function numberToVolume(value: number) {
	return (
		new Intl.NumberFormat('ru-RU', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		}).format(value) + ' см3'
	)
}

export function numberToWeight(value: number) {
	return (
		new Intl.NumberFormat('ru-RU', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		}).format(value) + ' г'
	)
}

export function firstUpper(value: string) {
	return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}
