<template>
	<MenuWrapper>
		<CatalogMenu v-if="showCatalogMenu" @click.stop />
		<OrdersMenu v-if="showOrdersMenu" @click.stop />
		<SingleJewelryMenu v-if="showSingleJewelryMenu" />
		<hr class="dropdown-divider" />
		<a @click.prevent="addNewJewelry" class="dropdown-item" href> <i class="fas fa-plus"></i> Украшение </a>
		<hr class="dropdown-divider" />
		<router-link :to="{ name: 'Orders' }" class="dropdown-item"><i class="fas fa-chart-line"></i> Список заказов</router-link>
		<a @click.prevent="addNewOrder" class="dropdown-item" href> <i class="fas fa-plus"></i> Заказ </a>
	</MenuWrapper>
</template>

<script>
import { defineComponent } from 'vue'
import MenuWrapper from '@/components/app/menu-wrapper.component.vue'
import CatalogMenu from '@/components/header/menu/catalog-menu.component.vue'
import OrdersMenu from '@/components/header/menu/orders-menu.component.vue'
import SingleJewelryMenu from '@/components/header/menu/single-jewelry-menu.component.vue'
import { ItemStatus } from '@/models/filter.models'
import { Filter, SingleJewelry, Orders } from '@/store'
import { router } from '@/router'

export default defineComponent({
	name: 'HeaderMenu',
	components: {
		MenuWrapper,
		CatalogMenu,
		OrdersMenu,
		SingleJewelryMenu,
	},

	methods: {
		async addNewJewelry() {
			SingleJewelry.addNewJewelry()
		},

		async addNewOrder() {
			Orders.addNewOrder()
		},
	},

	computed: {
		itemStatus: {
			get() {
				return Filter.catalogItemStatus
			},
			set(value) {
				Filter.updateCatalogItemStatus(value)
			},
		},
		itemStatusOptions() {
			return ItemStatus
		},
		showCatalogMenu() {
			return router.currentRoute.value.name === 'Catalog'
		},
		showOrdersMenu() {
			return router.currentRoute.value.name === 'Orders'
		},
		showSingleJewelryMenu() {
			return router.currentRoute.value.name === 'SingleJewelry'
		},
	},
})
</script>
