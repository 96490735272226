export enum ItemStatus {
	Publish = 'publish',
	Draft = 'draft',
}

export enum CalcStatus {
	All = 'all',
	True = 'true',
	False = 'false',
	None = 'none',
}

export interface CatalogFilter {
	searchString: string
	catalogItemStatus: ItemStatus
	priceMin: number
	priceMax: number
	calcStatus: CalcStatus
	categoryId: number | null
	collectionId: number | null
}

export enum LayoutVariants {
	catalog = 'catalog',
	price = 'price',
}
