
import { defineComponent } from 'vue'
import { User } from '@/store'

import LoadingBar from '@/components/app/loading-bar.component.vue'
import Header from '@/components/header/header.component.vue'
import Toasts from '@/components/app/toasts-controller.component.vue'

export default defineComponent({
	name: 'App',

	components: {
		LoadingBar,
		Header,
		Toasts,
	},

	computed: {
		loggedIn() {
			return User.loggedIn
		},

		login(): boolean {
			return this.$route.name === 'LogIn'
		},
	},

	mounted() {
		console.log('Start app')
	},
})
