import { createStore } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import VuexPersistence from 'vuex-persist'

import { server } from '@/store/server'

import { AppModule } from '@/store/modules/app.module'
import { ServerModule } from '@/store/modules/server.module'
import { UserModule } from '@/store/modules/user.module'
import { JewelryCatalogModule } from '@/store/modules/catalog.module'
import { CatalogFilterModule } from '@/store/modules/catalog-filter.module'
import { SingleJewelryModule } from '@/store/modules/single-jewelry.module'
import { MediaModule } from '@/store/modules/media.module'
import { ToastsModule } from '@/store/modules/toasts.module'
import { OrdersModule } from '@/store/modules/orders.module'
import { OrdersFilterModule } from '@/store/modules/orders-filter.module'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	modules: ['AppModule', 'UserModule', 'CatalogFilterModule', 'OrdersFilterModule'],
})

export const store = createStore({
	plugins: [vuexLocal.plugin],
	modules: {
		AppModule,
		ServerModule,
		UserModule,
		JewelryCatalogModule,
		CatalogFilterModule,
		SingleJewelryModule,
		MediaModule,
		ToastsModule,
		OrdersModule,
		OrdersFilterModule,
	},
})

// Modules export
export const App = getModule(AppModule, store)
export const Server = getModule(ServerModule, store)
export const User = getModule(UserModule, store)
export const JewelryCatalog = getModule(JewelryCatalogModule, store)
export const Filter = getModule(CatalogFilterModule, store)
export const SingleJewelry = getModule(SingleJewelryModule, store)
export const Media = getModule(MediaModule, store)
export const Toasts = getModule(ToastsModule, store)
export const Orders = getModule(OrdersModule, store)
export const OrdersFilter = getModule(OrdersFilterModule, store)

store.watch(
	() => User.user.token,
	() => {
		server.defaults.headers.Authorization = User.user.token ? 'Bearer ' + User.user.token : ''
		console.log('JWT token changed')
	},
	{ immediate: true }
)
