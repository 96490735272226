import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { Toast, ToastVariants } from '@/models/toasts.models'

@Module({
	name: 'ToastsModule',
})
export class ToastsModule extends VuexModule {
	toasts = Array<Toast>()

	@Action
	newToast(newToast: { message: string; variant?: ToastVariants; autohide?: boolean }) {
		const toast: Toast = {
			id: Math.floor(Math.random() * Date.now()),
			message: newToast.message,
			variant: newToast.variant || ToastVariants.primary,
			autohide: newToast.autohide !== undefined ? newToast.autohide : true,
		}
		this.NEW_TOAST(toast)
	}

	@Mutation
	private NEW_TOAST(toast: Toast) {
		this.toasts.unshift(toast)
		// console.log(toast)
	}

	@Action
	async removeToast(id: number) {
		this.REMOVE_TOAST(id)
	}

	@Mutation
	private REMOVE_TOAST(id: number) {
		// console.log(id)
		this.toasts = this.toasts.filter((toast) => toast.id !== id)
	}
}
