import { VuexModule, Module, MutationAction, Action } from 'vuex-module-decorators'
import { CatalogFilter, ItemStatus, CalcStatus, LayoutVariants } from '@/models/filter.models'

@Module({
	name: 'CatalogFilterModule',
})
export class CatalogFilterModule extends VuexModule implements CatalogFilter {
	searchString = ''
	catalogItemStatus = ItemStatus.Publish
	priceMin = 0
	priceMax = 0
	calcStatus = CalcStatus.All
	layout = LayoutVariants.catalog
	categoryId = null
	collectionId = null

	@MutationAction({ mutate: ['searchString'] })
	async updateSearchString(searchString: string) {
		return { searchString }
	}

	@MutationAction({ mutate: ['catalogItemStatus'] })
	async updateCatalogItemStatus(catalogItemStatus: ItemStatus) {
		return { catalogItemStatus }
	}

	@MutationAction({ mutate: ['priceMin'] })
	async updatePriceMin(priceMin: number) {
		return { priceMin }
	}

	@MutationAction({ mutate: ['priceMax'] })
	async updatePriceMax(priceMax: number) {
		return { priceMax }
	}

	@MutationAction({ mutate: ['categoryId'] })
	async updateCatigoryId(categoryId: number | null) {
		return { categoryId }
	}

	@MutationAction({ mutate: ['collectionId'] })
	async updateCollectionId(collectionId: number | null) {
		return { collectionId }
	}

	@MutationAction({ mutate: ['calcStatus'] })
	async updateCalcStatus(calcStatus: CalcStatus) {
		return { calcStatus }
	}

	@MutationAction({ mutate: ['layout'] })
	async updateLayout(layout: LayoutVariants) {
		return { layout }
	}

	@Action
	resetFilter() {
		this.updateSearchString('')
		this.updateCatalogItemStatus(ItemStatus.Publish)
		this.updatePriceMin(0)
		this.updatePriceMax(0)
		this.updateCatigoryId(null)
		this.updateCollectionId(null)
		this.updateCalcStatus(CalcStatus.All)
	}

	get filterChanged() {
		return this.searchString === '' &&
			this.catalogItemStatus === ItemStatus.Publish &&
			this.priceMin === 0 &&
			this.priceMax === 0 &&
			this.categoryId === null &&
			this.collectionId === null &&
			this.calcStatus === CalcStatus.All
			? false
			: true
	}
}
