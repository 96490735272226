import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { server } from '@/store/server'

@Module({
	name: 'MediaModule',
})
export class MediaModule extends VuexModule {
	images = []

	@Action
	async updateImages() {
		const response = await server.get('/app/v1/media', { params: { type: 'all' } })
		// eslint-disable-next-line
		const images = [].concat(...Object.values(response.data as Record<string, any>))
		this.UPDATE_IMAGES(images)
	}

	@Mutation
	private UPDATE_IMAGES(images: never[]) {
		this.images = images
		console.log('[MEDIA] Images updated')
	}

	@Action
	async uploadImage(image: { data: Blob; filename: string }) {
		// console.log(image)
		const form = new FormData()
		form.append('file', image.data, image.filename)
		await server.post('/wp/v2/media', form)
		console.log('[MEDIA] Image ' + image.filename + ' uploaded')
		this.updateImages()
	}
}
