
import { defineComponent, nextTick } from 'vue'
import { Filter } from '@/store'
import { LayoutVariants } from '@/models/filter.models'

import JewelryCatalogItem from '@/components/catalog/catalog-item.component.vue'
import JewelryPriceItem from '@/components/catalog/price-item.component.vue'

import { imageLazyLoad } from '@/scripts/imageLazyLoad'
import { Server } from '@/store'

export default defineComponent({
	name: 'JewelryCatalogContainer',
	components: { JewelryCatalogItem, JewelryPriceItem },

	props: ['jewelryItems'],

	computed: {
		loading() {
			return Server.loading
		},
		LayoutVariants() {
			return LayoutVariants
		},
		layout() {
			return Filter.layout
		},
	},

	watch: {
		jewelryItems: {
			immediate: true,
			handler() {
				nextTick(() => {
					imageLazyLoad()
				})
			},
		},
		layout() {
			nextTick(() => {
				imageLazyLoad()
			})
			// console.log(Search.layout)
		},
	},
})
