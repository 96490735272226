<template>
	<div v-show="loading" class="loading-bar">
		<div class="line"></div>
		<div class="subline inc"></div>
		<div class="subline dec"></div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Server } from '@/store'

export default defineComponent({
	name: 'HeaderLoading',

	computed: {
		loading() {
			return Server.loading
		},
	},
})
</script>

<style lang="scss">
.loading-bar {
	position: fixed;
	width: 100%;
	height: 5px;
	overflow-x: hidden;
	z-index: 1000;

	.line {
		position: fixed;
		opacity: 0.4;
		background: var(--bs-primary);
		width: 150%;
		height: 5px;
		z-index: 600;
	}
	.subline {
		position: fixed;
		background: var(--bs-primary);
		height: 5px;
		z-index: 700;
	}
	.inc {
		animation: increase 2s infinite;
	}
	.dec {
		animation: decrease 2s 0.5s infinite;
	}

	@keyframes increase {
		from {
			left: -5%;
			width: 5%;
		}
		to {
			left: 130%;
			width: 100%;
		}
	}
	@keyframes decrease {
		from {
			left: -80%;
			width: 80%;
		}
		to {
			left: 110%;
			width: 10%;
		}
	}
}
</style>
