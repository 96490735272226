import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'

import { server } from '@/store/server'
import { Filter } from '@/store'

@Module({
	name: 'JewelryCatalogModule',
})
export class JewelryCatalogModule extends VuexModule {
	oldParams = ''
	jewelryItems = []

	@Action({ commit: 'LOAD_JEWELRY_ITEMS' })
	async loadJewelryItems(force = false) {
		const searchString = Filter.searchString.trim()
		const params = {
			search: searchString.length > 2 ? searchString : '',
			status: Filter.catalogItemStatus,
			priceMin: Filter.priceMin,
			priceMax: Filter.priceMax,
			categoryId: Filter.categoryId,
			collectionId: Filter.collectionId,
			calcStatus: Filter.calcStatus,
		}

		const fields = JSON.stringify(params)

		if (this.oldParams !== fields || force) {
			const response = await server.get('/app/v1/jewelry', {
				params: params,
			})

			return { jewelryItems: response.data, oldParams: fields }
		}
	}

	@Mutation
	LOAD_JEWELRY_ITEMS(response: { jewelryItems: []; oldParams: string }) {
		if (response !== undefined) {
			this.jewelryItems = response.jewelryItems
			this.oldParams = response.oldParams
		}
	}

	// @Action
	// async exportExcel() {
	// 	const ExcelJS = require('exceljs')
	// 	const saveAs = require('file-saver')

	// 	const workbook = new ExcelJS.Workbook()
	// 	const worksheet = workbook.addWorksheet('Прайс украшений ICHIEN')

	// 	worksheet.columns = [
	// 		{ header: 'Изображение', key: 'image', width: 30 },
	// 		{ header: 'Название', key: 'title', width: 30 },
	// 		{ header: 'Артикль', key: 'article', width: 12 },
	// 		{ header: 'Описание', key: 'description', width: 25 },
	// 		{ header: 'Себестоимость', key: 'costPrice', width: 20 },
	// 		{ header: 'Цена', key: 'price', width: 20 },
	// 	]

	// 	worksheet.getColumn('costPrice').numFmt = '# ##0 ₽'
	// 	worksheet.getColumn('price').numFmt = '# ##0 ₽'

	// 	const row = worksheet.lastRow
	// 	row.height = 20

	// 	// eslint-disable-next-line
	// 	this.jewelryItems.forEach(async (element: any) => {
	// 		const row = worksheet.addRow({
	// 			image: element.thumbnail,
	// 			title: element.title,
	// 			article: element.slug,
	// 			description: element.description,
	// 			costPrice: (element.price / (100 + +element.extraPercent)) * 100,
	// 			price: +element.price,
	// 		})
	// 		row.height = 120

	// 		// const response = await server.get('http://bit.ly/18g0VNp')
	// 		// console.log(response)
	// 	})

	// 	const buffer = await workbook.xlsx.writeBuffer()
	// 	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
	// 	const blob = new Blob([buffer], { type: fileType })
	// 	saveAs(blob, 'Прайс украшений ICHIEN.xlsx')
	// }
}
