
import { defineComponent } from 'vue'
import { numberToRUB } from '@/scripts/filters'
import { User } from '@/store'

export default defineComponent({
	name: 'JewelryCatalogItem',
	props: { jewelryItem: Object },
	computed: {
		admin() {
			return User.admin
		},
	},
	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
	},
})
