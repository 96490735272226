<template>
	<div class="dropdown">
		<div v-show="showCircle" class="menu-circle"></div>
		<div id="hamburger" data-bs-toggle="dropdown">
			<span></span>
			<span></span>
			<span></span>
		</div>
		<div class="dropdown-menu mt-3 rounded-3"><slot></slot></div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Filter, OrdersFilter } from '@/store'
import { router } from '@/router'

export default defineComponent({
	name: 'MenuWrapper',
	computed: {
		showCircle() {
			return (
				(Filter.filterChanged && router.currentRoute.value.name === 'Catalog') ||
				(OrdersFilter.ordersFilterChanged && router.currentRoute.value.name === 'Orders')
			)
		},
	},
})
</script>

<style lang="scss" scoped>
#hamburger {
	cursor: pointer;
	padding: 10px;
	border-radius: 10px;
	transition: 0.5s background-color;

	&:hover,
	&:focus {
		background: rgba(0, 0, 0, 0.1);
	}

	span {
		display: block;
		background: var(--bs-gray);
		width: 25px;
		height: 2px;
		border-radius: 1rem;
		&:nth-child(2) {
			margin: 5px 0;
		}
	}
}
.menu-circle {
	--size: 10px;
	--offset: -2px;
	height: var(--size);
	width: var(--size);
	background-color: var(--bs-primary);
	border-radius: 100px;
	position: absolute;
	right: var(--offset);
	top: var(--offset);
}
.dropdown-menu {
	width: 250px;
}
</style>
