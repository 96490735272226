
import { defineComponent } from 'vue'
import { numberToRUB } from '@/scripts/filters'

export default defineComponent({
	name: 'JewelryPriceItem',
	props: { jewelryItem: Object },
	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
	},
})
