<template>
	<input v-model="searchString" id="search-header-input" type="text" placeholder="Поиск..." class=" form-control rounded-pill px-3" />
</template>

<script>
import { defineComponent } from 'vue'
import { Filter } from '@/store'
import _ from 'lodash'

export default defineComponent({
	name: 'HeaderSearch',
	data: () => ({
		filter: {},
	}),

	computed: {
		searchString: {
			get() {
				return Filter.searchString
			},
			set: _.debounce((value) => {
				Filter.updateSearchString(value)
			}, 300),
		},
	},
})
</script>

<style lang="scss" scoped>
#search-header-input {
	transition-duration: 0.5s;
	transition-property: width, box-shadow, border;
	width: 200px;

	&:focus {
		width: 250px;
	}
}
</style>
