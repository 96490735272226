<template>
	<div class="header bg-white shadow-sm row align-items-center w-100 m-0">
		<div v-if="showMenu" class="col-auto">
			<HeaderMenu />
		</div>

		<div class="col-auto" :class="{ 'd-none d-sm-flex': showSearch }">
			<router-link :to="{ name: 'Catalog' }">
				<img :src="require('@/assets/ichien-text-logo.svg')" height="27" />
			</router-link>
		</div>

		<div v-if="showSearch" class="col-auto">
			<HeaderSearch />
		</div>

		<!-- <div class="col-auto">
				<button @click="request" type="button" class="btn btn-sm btn-primary">Request</button>
			</div> -->

		<div class="col-auto ms-auto">
			<HeaderAvatar />
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

import HeaderMenu from '@/components/header/menu/header-menu.component.vue'
import HeaderSearch from '@/components/header/header-search.component.vue'
import HeaderAvatar from '@/components/header/header-avatar.component.vue'
import { User } from '@/store'
import { router } from '@/router'

export default defineComponent({
	name: 'Header',
	components: {
		HeaderSearch,
		HeaderMenu,
		HeaderAvatar,
	},
	computed: {
		showMenu() {
			return User.admin
		},
		showSearch() {
			return router.currentRoute.value.name === 'Catalog'
		},
	},
})
</script>

<style lang="scss" scoped>
.header {
	height: var(--header-height);
	z-index: 200;
	border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	position: sticky;
	top: 0;
}
</style>
