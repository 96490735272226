<template>
	<LoadingBar />
	<Header v-if="loggedIn" />
	<router-view v-slot="{ Component }">
		<transition name="routerTransition" mode="out-in">
			<component :is="Component" />
		</transition>
	</router-view>
	<Toasts />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { User } from '@/store'

import LoadingBar from '@/components/app/loading-bar.component.vue'
import Header from '@/components/header/header.component.vue'
import Toasts from '@/components/app/toasts-controller.component.vue'

export default defineComponent({
	name: 'App',

	components: {
		LoadingBar,
		Header,
		Toasts,
	},

	computed: {
		loggedIn() {
			return User.loggedIn
		},

		login(): boolean {
			return this.$route.name === 'LogIn'
		},
	},

	mounted() {
		console.log('Start app')
	},
})
</script>

<style scoped>
/* .app-fullpage {
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.app-screen {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
}
.app-page {
	flex: 1;
	overflow-x: hidden;
	overflow-y: auto;
} */
.routerTransition-enter-active,
.routerTransition-leave-active {
	transition-duration: 0.25s;
	transition-property: transform, opacity;
	animation-timing-function: ease-in-out;
}
.routerTransition-enter,
.routerTransition-leave-to {
	opacity: 0;
}
</style>
