<template>
	<div class="px-2">
		<div class="text-center">
			Фильтр
			<a v-show="ordersFilterChanged" @click.prevent="resetFilter" class="link-danger position-absolute ps-1" href><i class="fas fa-undo"></i></a>
		</div>
		<div class="d-grid gap-2">
			<div>
				<div class="form-text">Сортировка</div>
				<select v-model="sort" class="form-select form-select-sm">
					<option :value="OrdersSort.StartDate">Давно взяли</option>
					<option :value="OrdersSort.EndDate">Скоро отдаём</option>
				</select>
			</div>
			<div>
				<div class="form-text">Готовность</div>
				<select v-model="completeness" class="form-select form-select-sm">
					<option :value="OrdersCompleteness.All">Все</option>
					<option :value="OrdersCompleteness.Incomplete">В работе</option>
					<option :value="OrdersCompleteness.Completed">Готовые</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { OrdersSort, OrdersCompleteness } from '@/models/orders.models'
import { OrdersFilter } from '@/store'

export default defineComponent({
	name: 'OrdersMenu',

	computed: {
		OrdersSort() {
			return OrdersSort
		},
		OrdersCompleteness() {
			return OrdersCompleteness
		},
		sort: {
			get() {
				return OrdersFilter.sort
			},
			set(value) {
				OrdersFilter.updateSort(value)
			},
		},
		completeness: {
			get() {
				return OrdersFilter.completeness
			},
			set(value) {
				OrdersFilter.updateCompleteness(value)
			},
		},

		ordersFilterChanged() {
			return OrdersFilter.ordersFilterChanged
		},
	},
	methods: {
		resetFilter() {
			OrdersFilter.resetFilter()
		},
	},
})
</script>
