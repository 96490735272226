export const imageLazyLoad = () => {
	const imageObserver = new IntersectionObserver((entries, imgObserver) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				// eslint-disable-next-line
				const lazyImage: any = entry.target
				lazyImage.src = lazyImage.dataset.src
				lazyImage.classList.remove('lazy-image')
				imgObserver.unobserve(lazyImage)
			}
		})
	})
	const images = document.querySelectorAll('img.lazy-image')
	images.forEach((image) => {
		imageObserver.observe(image)
	})
}
