import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { server } from '@/store/server'
import { App, Toasts, User } from '@/store'
import { router } from '@/router'

@Module({ name: 'SingleJewelryModule' })
export class SingleJewelryModule extends VuexModule {
	// eslint-disable-next-line
	fieldsGroup = {} as any
	// eslint-disable-next-line
	jewelryItem = {} as any
	images = []
	calcInfo = {}
	techInfo = {}
	categoriesList = {}
	tagsList = {}
	lastArticleNumber = 0
	jewelryItemChanged = false

	//Old fields
	private oldImagesFields = ''
	private oldCalcInfoFields = ''
	private oldTechInfoFields = ''
	private oldArticleFields = ''

	@Mutation
	private RESET_JEWELRY_ITEM() {
		this.oldImagesFields = this.oldCalcInfoFields = this.oldTechInfoFields = this.oldArticleFields = ''
		this.fieldsGroup = this.jewelryItem = this.calcInfo = this.techInfo = this.categoriesList = this.tagsList = {}
		this.images = []
		this.jewelryItemChanged = false
	}

	@Action
	async loadJewelryItem(id: string) {
		this.RESET_JEWELRY_ITEM()
		//Load fields group
		// const response = await server.get('app/v1/fields-group', {
		// 	params: { fieldsGroup: 'group_info' },
		// })
		// const fieldsGroup = response.data
		// //Load jewelry item
		// const params = {
		// 	_fields: 'id, title.raw, content.raw, status, acf, categories, tags',
		// 	context: 'edit',
		// 	// eslint-disable-next-line
		// } as any

		// // eslint-disable-next-line
		// let post = {} as any

		// if (+id) {
		// 	const response = await server.get('wp/v2/posts/' + id, { params: params })
		// 	post = response.data
		// } else if (typeof id === 'string') {
		// 	params.slug = id
		// 	const response = await server.get('wp/v2/posts/', { params: params })
		// 	post = response.data[0]
		// }

		// Object.keys(fieldsGroup).forEach((key) => {
		// 	const value = fieldsGroup[key]
		// 	if (post.acf[key] == undefined || null) post.acf[key] = value.default_value !== undefined ? value.default_value : ''
		// })

		// const jewelryItem = post

		const response = await server.get('app/v1/jewelry/' + id)

		const fieldsGroup = response.data.fieldsGroup
		const jewelryItem = response.data.jewelryItem

		this.UPDATE_FIELDS_GROUP(fieldsGroup)
		await this.updateJewelryItem({ jewelryItem: jewelryItem, editStatus: false })
	}

	@Mutation
	private UPDATE_FIELDS_GROUP(fieldsGroup: {}) {
		this.fieldsGroup = fieldsGroup
		console.log('[Single jewelry module] Fields group loaded')
	}

	@Action
	async updateJewelryItem(payload: { jewelryItem: {}; editStatus: boolean }) {
		this.UPDATE_JEWELRY_ITEM(payload)
		this.updateJewelryItemInfo()
	}

	@Mutation
	private UPDATE_JEWELRY_ITEM(payload: { jewelryItem: {}; editStatus: boolean }) {
		this.jewelryItem = payload.jewelryItem
		this.jewelryItemChanged = payload.editStatus
		console.log('[Single jewelry module] Jewelry item updated')
	}

	@Action
	private async updateJewelryItemInfo() {
		this.loadJewelryItemImages()
		if (App.jewelryItemEdit && User.admin) this.getLastArticleNumber()
		if (User.admin && this.isCalc) this.getCalcInfo()
		else this.UPDATE_CALC_INFO({ calcInfo: {}, oldFields: '' })
		if (User.admin || User.jeweler) this.getTechInfo()
	}

	@Action
	async getTaxonomies() {
		const params = { _fields: 'id, name', per_page: 100, orderby: 'count', order: 'desc' }

		const categories = await server.get('/wp/v2/categories', { params: params })
		const tags = await server.get('/wp/v2/tags', { params: params })

		const categoriesList = categories.data
		const tagsList = tags.data
		this.UPDATE_TAXONOMIES({ categoriesList, tagsList })
	}

	@Mutation
	private UPDATE_TAXONOMIES(payload: { categoriesList: {}; tagsList: {} }) {
		this.categoriesList = payload.categoriesList
		this.tagsList = payload.tagsList
	}

	@Action
	async loadJewelryItemImages() {
		const imagesFields = {
			[this.fieldsGroup.photo.name]: this.jewelryItem.acf.photo,
			[this.fieldsGroup.render.name]: this.jewelryItem.acf.render,
			[this.fieldsGroup.screenshot.name]: this.jewelryItem.acf.screenshot,
			[this.fieldsGroup.sketch.name]: this.jewelryItem.acf.sketch,
		}
		const oldFields = JSON.stringify(imagesFields)

		if (this.oldImagesFields !== oldFields) {
			const response = await server.get('/app/v1/media', {
				params: { images: imagesFields, type: 'array' },
			})

			const images = [].concat(...Object.values(response.data as Record<string, []>))
			this.LOAD_JEWELRY_ITEM_IMAGES({ images, oldFields })
		}
	}

	@Mutation
	private LOAD_JEWELRY_ITEM_IMAGES(payload: { images: never[]; oldFields: string }) {
		this.images = payload.images
		this.oldImagesFields = payload.oldFields
		console.log('[Single jewelry module] Jewelry item images loaded')
	}

	@Action
	async getCalcInfo() {
		const calcInfoFields = {
			volume: this.jewelryItem.acf.volume,
			weight925: this.jewelryItem.acf.weight925,
			weight585: this.jewelryItem.acf.weight585,
			weight750: this.jewelryItem.acf.weight750,
			weight950: this.jewelryItem.acf.weight950,

			metal: this.jewelryItem.acf.articleMetal,
			metalTitle: this.fieldsGroup.articleMetal.choices[this.jewelryItem.acf.articleMetal],

			diamondStones: this.jewelryItem.acf.diamondStones,
			otherStones: this.jewelryItem.acf.otherStones,
			additionalExpenses: this.jewelryItem.acf.additionalExpenses,

			sketchDays: this.jewelryItem.acf.sketchDays,
			modelingDays: this.jewelryItem.acf.modelingDays,
			mountingDays: this.jewelryItem.acf.mountingDays,

			laserEngraving: this.jewelryItem.acf.laserEngraving,
			package: this.jewelryItem.acf.package,

			extraPercent: this.jewelryItem.acf.extraPercent,
		}

		const oldFields = JSON.stringify(calcInfoFields)
		if (this.oldCalcInfoFields !== oldFields) {
			const response = await server.get('/app/v1/info/calcinfo', {
				params: { fields: calcInfoFields },
			})

			const calcInfo = response.data
			this.UPDATE_CALC_INFO({ calcInfo, oldFields })
		}
	}

	@Mutation
	private UPDATE_CALC_INFO(payload: { calcInfo: {}; oldFields: string }) {
		this.calcInfo = payload.calcInfo
		this.oldCalcInfoFields = payload.oldFields
		console.log('[Single jewelry module] Calc info loaded')
	}

	@Action
	async getTechInfo() {
		const techInfoFields = {
			volume: this.jewelryItem.acf.volume,
			weight925: this.jewelryItem.acf.weight925,
			weight585: this.jewelryItem.acf.weight585,
			weight750: this.jewelryItem.acf.weight750,
			weight950: this.jewelryItem.acf.weight950,
			diamondStones: this.jewelryItem.acf.diamondStones,
			otherStones: this.jewelryItem.acf.otherStones,
		}

		const oldFields = JSON.stringify(techInfoFields)
		if (this.oldTechInfoFields !== oldFields) {
			const response = await server.get('/app/v1/info/techinfo', { params: { fields: techInfoFields } })
			const techInfo = response.data

			this.UPDATE_TECH_INFO({ techInfo, oldFields })
		}
	}

	@Mutation
	private UPDATE_TECH_INFO(payload: { techInfo: {}; oldFields: string }) {
		this.techInfo = payload.techInfo
		this.oldTechInfoFields = payload.oldFields
		console.log('[Single jewelry module] Tech info loaded')
	}

	@Action
	async getLastArticleNumber() {
		const params = { exclude: this.jewelryItem.id, articleType: this.jewelryItem.acf.articleType }
		const oldFields = JSON.stringify(params)

		if (this.oldArticleFields !== oldFields) {
			const response = await server.get('app/v1/info/last-article-number', { params: params })
			this.UPDATE_LAST_ARTICLE_NUMBER({ lastArticleNumber: response.data, oldFields: oldFields })
		}
	}

	@Mutation
	private UPDATE_LAST_ARTICLE_NUMBER(payload: { lastArticleNumber: number; oldFields: string }) {
		this.lastArticleNumber = payload.lastArticleNumber
		this.oldArticleFields = payload.oldFields
		console.log('[Single jewelry module] Last article number updated')
	}

	@Action
	async saveJewelryItem() {
		console.log('[Single jewelry module] Saving ' + this.jewelryItem.id)

		const response = await server.patch('/app/v1/jewelry', this.jewelryItem)
		console.log('[Single jewelry module] ' + this.jewelryItem.id + ' saved')
		this.SAVE_JEWELRY_ITEM()

		if (router.currentRoute.value.params.id !== response.data) {
			console.log('Redirect from /' + router.currentRoute.value.params.id + ' to new url /' + response.data)
			router.replace({ name: 'SingleJewelry', params: { id: response.data } })
		}
	}

	@Mutation
	private SAVE_JEWELRY_ITEM() {
		this.jewelryItemChanged = false
		Toasts.newToast({ message: 'Изменения сохранены' })
	}

	get article() {
		// if (typeof this.jewelryItem.acf !== 'undefined') {
		const articleNumber = this.jewelryItem.acf ? this.jewelryItem.acf.articleNumber + '' : ''
		let article = ''
		article += this.jewelryItem.acf.articleType
		article += ('0000' + articleNumber).substring(articleNumber.length)
		article += this.jewelryItem.acf.articleCompl

		switch (parseInt(this.jewelryItem.acf.articleMetal)) {
			case 925:
				article += '0'
				break
			case 585:
				article += '5'
				break
			case 750:
				article += '7'
				break
			case 950:
				article += '9'
				break
		}

		if (this.jewelryItem.acf.articleMod) article += '-' + this.jewelryItem.acf.articleMod

		return article
		// } else return ''
	}

	get isCalc() {
		return this.jewelryItem.acf.isCalc
	}

	@Action
	async addNewJewelry() {
		const result = confirm(`Создать новое украшение?`)
		if (result) {
			const post = {
				title: 'Новое украшение',
				status: 'draft',
			}
			const response = await server.post('/wp/v2/jewelry', post, { params: { _fields: 'id' } })
			router.push({ name: 'SingleJewelry', params: { id: response.data.id } })
			console.log('[Single jewelry module] Add new jewelry')
		}
	}

	@Action
	async deleteJewelry() {
		const result = confirm(`Переместить "${this.jewelryItem.title}" в корзину?\nОкончательно удалить можно в панели управления.`)
		if (result) {
			console.log('[Single jewelry module] Delete jewelry')
			await server.delete('/wp/v2/jewelry/' + this.jewelryItem.id)
			router.push({ name: 'Catalog' })
		}
	}
}
