import axios from 'axios'
import { Server } from '@/store'
// import { router } from '@/router'
import { User } from '@/store'

export const server = axios.create({
	baseURL: 'https://api.database.ichien.ru/wp-json/',
	timeout: 10000,
	headers: {
		'Content-Type': 'application/json',
	},
})

server.interceptors.request.use(
	(config) => {
		Server.addLoading()
		return config
	},
	(error) => {
		Server.removeLoading()
		return Promise.reject(error)
	}
)

server.interceptors.response.use(
	(config) => {
		Server.removeLoading()
		return config
	},
	(error) => {
		Server.removeLoading()
		if (error.response.data.data.status === 403) {
			User.logOut()
		}
		return Promise.reject(error)
	}
)
