<template>
	<div class="dropdown">
		<div
			class="d-flex justify-content-center align-items-center bg-secondary bg-gradient rounded-circle text-white small fs-6"
			style="height: 2.5rem; width: 2.5rem"
			role="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
		>
			{{ avatarName }}
		</div>

		<ul class="dropdown-menu mt-3 dropdown-menu-right dropdown-menu-dark rounded-3">
			<li>
				<a class="dropdown-item" href="https://api.database.ichien.ru/wp-admin/" target="_blank">
					<i class="fas fa-tools"></i> Панель управления
					<i class="fas fa-external-link-alt"></i>
				</a>
			</li>
			<li>
				<a @click.prevent="logOut()" class="dropdown-item" href> <i class="fas fa-sign-out-alt"></i> Выйти </a>
			</li>
		</ul>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { User } from '@/store'

export default defineComponent({
	name: 'HeaderAvatar',
	computed: {
		avatarName() {
			return User.user.user_nicename.charAt(0).toUpperCase()
		},
	},
	methods: {
		logOut() {
			User.logOut()
		},
	},
})
</script>

<style lang="scss" scoped></style>
