<template>
	<div class="toast-container position-fixed bottom-0 start-0 p-3 overflow-hidden">
		<Toast v-for="toast in toasts" :key="toast.id" :id="toast.id" :message="toast.message" :variant="toast.variant" :autohide="toast.autohide" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Toasts } from '@/store'

import Toast from '@/components/app/toast.component.vue'

export default defineComponent({
	name: 'ToastsController',
	components: {
		Toast,
	},

	mounted() {
		// setTimeout(() => Toasts.newToast({ message: 'Test toast', autohide: false }), 100)
		// setTimeout(() => Toasts.newToast({ message: 'Test toast', variant: ToastVariants.info }), 300)
		// setTimeout(() => Toasts.newToast({ message: 'Warning test toast', variant: ToastVariants.warning }), 1000)
		// setTimeout(() => Toasts.newToast({ message: 'Danger test toast', variant: ToastVariants.danger }), 2000)
	},

	computed: {
		toasts() {
			return Toasts.toasts
		},
	},
})
</script>
