<template>
	<div class="px-2">
		<div class="text-center">
			Фильтр <a v-show="filterChanged" @click.prevent="resetFilter" class="link-danger position-absolute ps-1" href><i class="fas fa-undo"></i></a>
		</div>
		<div class="d-grid gap-2">
			<div>
				<div class="form-text">Статус в каталоге</div>
				<select v-model="itemStatus" class="form-select form-select-sm">
					<option :value="itemStatusOptions.Publish">Опубликовано</option>
					<option :value="itemStatusOptions.Draft">Черновик</option>
				</select>
			</div>
			<div>
				<div class="form-text">Категории</div>
				<select v-model="categoryId" class="form-select form-select-sm">
					<option :value="null">Все</option>
					<option v-for="category in categories" :value="category.id" :key="category.id">
						{{ category.name }}
					</option>
				</select>
			</div>
			<div>
				<div class="form-text">Коллекции</div>
				<select v-model="collectionId" class="form-select form-select-sm">
					<option :value="null">Все</option>
					<option v-for="tag in tags" :value="tag.id" :key="tag.id">
						{{ tag.name }}
					</option>
				</select>
			</div>
			<div>
				<div class="form-text">
					Диапозон стоимости <a v-show="priceMin + priceMax !== 0" @click.prevent="clearPriceRange" class="link-danger" href>очистить</a>
				</div>
				<div class="input-group input-group-sm">
					<input v-model="priceMin" type="number" min="0" max="10000000" class="form-control" />
					<input v-model="priceMax" type="number" min="0" max="10000000" class="form-control" />
				</div>
			</div>
			<div v-if="admin">
				<div class="form-text">Смета</div>
				<select v-model="calcStatus" class="form-select form-select-sm">
					<option :value="calcStatusOptions.All">Все</option>
					<option :value="calcStatusOptions.True">Верна</option>
					<option :value="calcStatusOptions.False">Не верна</option>
					<option :value="calcStatusOptions.None">Без сметы</option>
				</select>
			</div>
			<div class="text-center">
				Отображение
				<!-- Отображение <a @click.prevent="exportExcel" class="" href><i class="fas fa-print"></i></a> -->
			</div>
			<div>
				<select v-model="layout" class="form-select form-select-sm">
					<option :value="LayoutVariants.catalog">Каталог</option>
					<option :value="LayoutVariants.price">Прайс</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { ItemStatus, CalcStatus, LayoutVariants } from '@/models/filter.models'
import { Filter, SingleJewelry, User } from '@/store'

import _ from 'lodash'

export default defineComponent({
	name: 'CatalogMenu',
	computed: {
		admin() {
			return User.admin
		},

		itemStatusOptions() {
			return ItemStatus
		},

		itemStatus: {
			get() {
				return Filter.catalogItemStatus
			},
			set(value) {
				Filter.updateCatalogItemStatus(value)
			},
		},

		calcStatusOptions() {
			return CalcStatus
		},

		calcStatus: {
			get() {
				return Filter.calcStatus
			},
			set(value) {
				Filter.updateCalcStatus(value)
			},
		},

		categoryId: {
			get() {
				return Filter.categoryId
			},
			set(value) {
				Filter.updateCatigoryId(value)
			},
		},

		collectionId: {
			get() {
				return Filter.collectionId
			},
			set(value) {
				Filter.updateCollectionId(value)
			},
		},

		categories() {
			return SingleJewelry.categoriesList
		},

		tags() {
			return SingleJewelry.tagsList
		},

		priceMin: {
			get() {
				return +Filter.priceMin
			},
			set: _.debounce((value) => {
				Filter.updatePriceMin(value)
			}, 500),
		},
		priceMax: {
			get() {
				return +Filter.priceMax
			},
			set: _.debounce((value) => {
				Filter.updatePriceMax(value)
			}, 500),
		},

		filterChanged() {
			return Filter.filterChanged
		},

		// Layout variants
		LayoutVariants() {
			return LayoutVariants
		},

		layout: {
			get() {
				return Filter.layout
			},
			set(value) {
				Filter.updateLayout(value)
			},
		},
	},
	methods: {
		clearPriceRange() {
			Filter.updatePriceMin(0)
			Filter.updatePriceMax(0)
			console.log('[Catalog filter] Price range cleared')
		},
		resetFilter() {
			Filter.resetFilter()
		},
	},
})
</script>
