import { VuexModule, Module, MutationAction, Action, Mutation } from 'vuex-module-decorators'
import { Order, JewelryWithCalc, OrdersSort, OrderType, OrdersCompleteness } from '@/models/orders.models'
import { server } from '@/store/server'
import { router } from '@/router'
import { Toasts, OrdersFilter } from '@/store'
import { ToastVariants } from '@/models/toasts.models'

@Module({
	name: 'OrdersModule',
})
export class OrdersModule extends VuexModule {
	orders = Array<Order>()
	jewelryWithCalc = Array<JewelryWithCalc>()
	ordersCostprice = []
	fieldsGroup = {}
	ordersLastUpdate = Date.now()
	allExpensesPerMonth = 0
	expensePerDay = 0
	hoveredOrderId = 0

	sort = OrdersSort.StartDate

	private msInDay = 1000 * 3600 * 24
	// private msInMonth = this.msInDay * 30

	@MutationAction({ mutate: ['orders', 'jewelryWithCalc', 'fieldsGroup', 'allExpensesPerMonth', 'expensePerDay', 'ordersLastUpdate'] })
	async getOrders() {
		const response = await server.get('/app/v1/orders')
		response.data.orders.forEach((order: Order) => {
			if (!order.orderType) order.orderType = OrderType.NewOrder
			if (order.costPrice === 0) order.costPrice = null
			if (order.prepay === 0) order.prepay = null
			if (order.price === 0) order.price = null
			order.startDate = new Date(order.startDate ? order.startDate : Date.now())
			order.endDate = new Date(order.endDate ? order.endDate : Date.now())
		})
		return {
			orders: response.data.orders,
			jewelryWithCalc: response.data.jewelryWithCalc,
			fieldsGroup: response.data.fieldsGroup,
			allExpensesPerMonth: response.data.allExpensesPerMonth,
			expensePerDay: Math.round((response.data.allExpensesPerMonth / 365) * 12),
			ordersLastUpdate: Date.now(),
		}
	}

	@Mutation
	HOVER_ORDER(id: number) {
		this.hoveredOrderId = id
	}

	@Action
	async saveOrder(order: Order) {
		await server.patch('/app/v1/orders', order)
		this.SAVE_ORDER(order)
	}

	@Mutation
	private SAVE_ORDER(newOrder: Order) {
		this.orders.filter((order) => order.id === newOrder.id)[0] = newOrder
		this.ordersLastUpdate = Date.now()
	}

	@Action
	async addNewOrder() {
		const result = confirm(`Создать новый заказ?`)
		if (result) {
			const post = {
				status: 'publish',
			}
			server.post('/wp/v2/jewelry_orders', post, { params: { _fields: 'id' } }).then(() => {
				Toasts.newToast({ message: 'Создан новый заказ', variant: ToastVariants.info })
				if (router.currentRoute.value.name === 'Orders') {
					this.getOrders()
				} else router.push({ name: 'Orders' })
			})
		}
	}

	@Action
	async deleteOrder(id: number) {
		const result = confirm(`Переместить заказ в корзину?\nОкончательно удалить можно в панели управления.`)
		if (result) {
			server.delete('/wp/v2/jewelry_orders/' + id).then(() => {
				Toasts.newToast({ message: 'Заказ удалён', variant: ToastVariants.danger })
				this.getOrders()
			})
		}
	}

	@MutationAction({ mutate: ['ordersLastUpdate'] })
	async updateOrdersLastUpdate() {
		return { ordersLastUpdate: Date.now() }
	}

	@Action
	async getOrdersCostprice() {
		const response = await server.get('/app/v1/info/orders-costprice', { params: { ordersJewelryId: this.ordersJewelryId } })
		this.UPDATE_ORDERS_COSTPRICE(response.data)
	}

	@Mutation
	private UPDATE_ORDERS_COSTPRICE(ordersCostprice: []) {
		this.ordersCostprice = ordersCostprice
		this.ordersLastUpdate = Date.now()
	}

	get lineChartData() {
		const orders: Array<Order> = Object.assign([], this.orders)
		orders.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())

		const expensePerDay = {} as { [key: string]: number }
		const expense = {} as { [key: string]: number }
		const income = {} as { [key: string]: number }
		const profit = {} as { [key: string]: number }

		const expensePerMonth = {} as { [key: string]: number }
		const profitPerMonth = {} as { [key: string]: number }

		orders.forEach((order) => {
			const selectedJewelry = this.jewelryWithCalc.filter((jewelry) => jewelry.id === order.jewelryId)[0] || ({} as JewelryWithCalc)

			const price = order.price || selectedJewelry.price || 0

			const costPrice = order.costPrice || this.ordersCostprice[order.jewelryId] || 0

			if (price) {
				let start = order.startDate.getTime()
				const end = order.endDate.getTime()

				// let startMonthDate = new Date(start).toDateString()
				// let initialMonth = new Date(start).getMonth() + 1 + '.' + new Date(start).getFullYear()

				const days = (end - start) / this.msInDay || 1

				while (start <= end) {
					const date = new Date(start)

					const currentDate = date.toDateString()

					expensePerDay[currentDate] = -this.expensePerDay
					expense[currentDate] = Math.round(expense[currentDate] - costPrice / days || -costPrice / days)
					income[currentDate] = Math.round(income[currentDate] + price / days || price / days)
					profit[currentDate] = Math.round(
						profit[currentDate] + (price - costPrice) / days || (price - costPrice) / days + expensePerDay[currentDate]
					)

					// const month = date.getFullYear() + ' ' + ('00' + (date.getMonth() + 1)).substring(date.getMonth().toString().length) + ' ' + '01'
					const month = ('00' + (date.getMonth() + 1)).substring(date.getMonth().toString().length) + '/01' + '/' + date.getFullYear() + ' GMT'
					// const currentMonth = new Date(month).toDateString()

					expensePerMonth[month] = Math.round(expensePerMonth[month] + costPrice / days || costPrice / days + this.allExpensesPerMonth)
					// expensePerMonth[month] += this.expensePerDay

					profitPerMonth[month] = Math.round(
						profitPerMonth[month] + (price - costPrice) / days || (price - costPrice) / days - this.allExpensesPerMonth
					)

					start += this.msInDay
				}
			}
		})
		return { expensePerDay, expense, income, profit, expensePerMonth, profitPerMonth }
	}

	get lineChartDataMonth() {
		const orders: Array<Order> = Object.assign([], this.orders)
		orders.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())

		const startMonth = orders[0].startDate
		const endMonth = orders[orders.length - 1].endDate

		const lineChartData = this.lineChartData

		const expensePerMonth = {} as { [key: string]: number }
		const expense = {} as { [key: string]: number }
		const income = {} as { [key: string]: number }
		const profit = {} as { [key: string]: number }

		return { expensePerMonth, expense, income, profit }
	}

	get timelineChartData() {
		const orders: Array<Order> = Object.assign([], this.orders)
		orders.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())

		const items = [{}]
		let i = 0

		orders.forEach((order) => {
			const selectedJewelry = this.jewelryWithCalc.filter((jewelry) => jewelry.id === order.jewelryId)[0] || ({} as JewelryWithCalc)

			const price = order.price || selectedJewelry.price || 0

			const costPrice = order.costPrice || this.ordersCostprice[order.jewelryId] || 0

			if (price) {
				const start = order.startDate.getTime()
				const end = order.endDate.getTime()
				const days = (end - start) / this.msInDay || 1
				const profit = Math.round((price - costPrice) / days)

				let color = '#aaa'

				switch (true) {
					case order.completed:
						color = '#aaa'
						break
					case profit < 1000:
						color = '#ff1a1a'
						break
					case profit >= 1000 && profit < 2000:
						color = '#ff6600'
						break
					case profit >= 2000 && profit < 4000:
						color = '#ffcc00'
						break
					case profit >= 4000 && profit < 6000:
						color = '#99ff33'
						break
					case profit >= 6000:
						color = '#66ff33'
						break
				}

				if (
					OrdersFilter.completeness === OrdersCompleteness.All ||
					(OrdersFilter.completeness === OrdersCompleteness.Incomplete && !order.completed) ||
					(OrdersFilter.completeness === OrdersCompleteness.Completed && order.completed)
				) {
					items[i] = {
						x: profit + '₽ ' + (order.title || selectedJewelry.title) + ' (' + days + ' д)',
						y: [start, end],
						fillColor: color,
						id: order.id,
					}

					i++
				}
			}
		})
		return items
	}

	get ordersJewelryId() {
		return this.orders.filter((order) => +order.jewelryId > 0).map((order) => +order.jewelryId)
	}
}
