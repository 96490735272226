export enum ToastVariants {
	success = 'success',
	primary = 'primary',
	info = 'info',
	warning = 'warning',
	danger = 'danger',
}

export interface Toast {
	id: number
	message: string
	variant: ToastVariants
	autohide: boolean
}
