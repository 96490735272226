<template>
	<div class="col p-2">
		<div class="container grow-element h-100 p-2 rounded-3 bg-white">
			<!-- Image -->
			<router-link
				:to="{
					name: 'SingleJewelry',
					params: { id: jewelryItem.status === 'publish' ? jewelryItem.slug : jewelryItem.id },
				}"
			>
				<div class="square-ratio">
					<img :data-src="jewelryItem.thumbnail" class="lazy-image" />
				</div>
			</router-link>

			<!-- Description -->
			<div class="mt-2">
				<div class="text-center">
					<!-- SKU -->
					<div class="text-uppercase font-monospace font-weight-light small" style="opacity: 0.5;">
						{{ jewelryItem.slug }}
						<nobr>
							<i class="fas fa-cube me-1" v-if="jewelryItem.embeddedF360"></i>
							<i class="fas fa-calculator me-1" v-if="jewelryItem.calc && admin" :class="{ 'text-danger': !jewelryItem.calcTrue }"></i>
							<div
								v-if="jewelryItem.calc && admin"
								class="small d-inline"
								:class="{
									'text-danger': jewelryItem.extraPercent < 40,
									'text-primary': jewelryItem.extraPercent >= 40 && jewelryItem.extraPercent < 70,
									'text-success': jewelryItem.extraPercent >= 70,
								}"
								v-html="'+' + jewelryItem.extraPercent + '%'"
							></div>
						</nobr>
					</div>

					<!-- Price -->
					<div class="my-1 h6 fw-bold">
						{{ numberToRUB(jewelryItem.price) }}
					</div>

					<!-- Title text-truncate-->
					<div class="truncate" v-html="jewelryItem.title"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { numberToRUB } from '@/scripts/filters'
import { User } from '@/store'

export default defineComponent({
	name: 'JewelryCatalogItem',
	props: { jewelryItem: Object },
	computed: {
		admin() {
			return User.admin
		},
	},
	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
	},
})
</script>

<style lang="scss" scoped>
a {
	text-decoration: none !important;
	color: inherit !important;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
</style>
