<template>
	<a @click.prevent="deleteJewelryItem" class="dropdown-item text-danger" href> <i class="fas fa-trash-alt"></i> Переместить в корзину </a>
</template>

<script>
import { defineComponent } from 'vue'
import { SingleJewelry } from '@/store'

export default defineComponent({
	name: 'SingleJewelryMenu',
	methods: {
		deleteJewelryItem() {
			SingleJewelry.deleteJewelry()
		},
	},
})
</script>
