<template>
	<div class="container" :class="loading ? 'post-container-out' : 'post-container-in'">
		<div v-if="layout === LayoutVariants.catalog" class="row catalog-container">
			<JewelryCatalogItem
				v-for="(jewelryItem, index) in jewelryItems"
				:jewelryItem="jewelryItem"
				:key="jewelryItem.id"
				:data-index="index"
			></JewelryCatalogItem>
		</div>

		<div v-if="layout === LayoutVariants.price" class="row justify-content-center" id="price-container">
			<JewelryPriceItem
				v-for="(jewelryItem, index) in jewelryItems"
				:jewelryItem="jewelryItem"
				:key="jewelryItem.id"
				:data-index="index"
			></JewelryPriceItem>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import { Filter } from '@/store'
import { LayoutVariants } from '@/models/filter.models'

import JewelryCatalogItem from '@/components/catalog/catalog-item.component.vue'
import JewelryPriceItem from '@/components/catalog/price-item.component.vue'

import { imageLazyLoad } from '@/scripts/imageLazyLoad'
import { Server } from '@/store'

export default defineComponent({
	name: 'JewelryCatalogContainer',
	components: { JewelryCatalogItem, JewelryPriceItem },

	props: ['jewelryItems'],

	computed: {
		loading() {
			return Server.loading
		},
		LayoutVariants() {
			return LayoutVariants
		},
		layout() {
			return Filter.layout
		},
	},

	watch: {
		jewelryItems: {
			immediate: true,
			handler() {
				nextTick(() => {
					imageLazyLoad()
				})
			},
		},
		layout() {
			nextTick(() => {
				imageLazyLoad()
			})
			// console.log(Search.layout)
		},
	},
})
</script>

<style scoped>
.post-container-out {
	transform-origin: center top;
	transition-duration: 0.3s;
	transition-property: transform, opacity;
	animation-timing-function: ease-in-out;
	opacity: 0;
	transform: translateY(30px);
}
.post-container-in {
	transform-origin: center top;
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	animation-name: fadeZoomIn;
}
@keyframes fadeZoomIn {
	from {
		opacity: 0;
		transform: translateY(30px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}
</style>
