export interface Order {
	id: number
	title: string
	orderType: string
	client: string
	jewelryId: number
	costPrice: number | null
	price: number | null
	prepay: number | null
	startDate: Date
	endDate: Date
	completed: boolean
}

export interface JewelryWithCalc {
	id: number
	title: string
	slug: string
	thumbnail: string
	price: number
	extraPercent: number
}

export enum OrdersSort {
	StartDate = 'startDate',
	EndDate = 'endDate',
}

export enum OrdersCompleteness {
	All = 'all',
	Completed = 'completed',
	Incomplete = 'incomplete',
}

export enum OrderType {
	NewOrder = 'newOrder',
	OrderFromFinished = 'orderFromFinished',
	Collection = 'collection',
}

export enum OrderIcon {
	NewOrder = 'fas fa-star',
	OrderFromFinished = 'fas fa-database',
	Collection = 'fas fa-chart-pie',
	All = 'fas fa-circle',
}
