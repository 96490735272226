<template>
	<div>
		<JewelryCatalogContainer :jewelryItems="jewelryItems" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Filter, JewelryCatalog, SingleJewelry } from '@/store'

import JewelryCatalogContainer from '@/components/catalog/catalog-container.component.vue'

export default defineComponent({
	name: 'Home',
	components: { JewelryCatalogContainer },

	computed: {
		catalogFilter() {
			return [
				Filter.searchString,
				Filter.catalogItemStatus,
				Filter.priceMin,
				Filter.priceMax,
				Filter.calcStatus,
				Filter.categoryId,
				Filter.collectionId,
			]
		},
		jewelryItems() {
			return JewelryCatalog.jewelryItems
		},
	},

	mounted() {
		JewelryCatalog.loadJewelryItems(true)
		SingleJewelry.getTaxonomies()
	},

	watch: {
		catalogFilter() {
			JewelryCatalog.loadJewelryItems()
		},
	},
})
</script>
