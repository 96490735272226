<template>
	<div class="col-11 col-md-10 col-lg-8 p-2">
		<div class="row p-2 rounded-3 bg-white align-items-center">
			<!-- Image -->
			<div class="col-2 p-0">
				<router-link
					:to="{
						name: 'SingleJewelry',
						params: { id: jewelryItem.status === 'publish' ? jewelryItem.slug : jewelryItem.id },
					}"
				>
					<div class="square-ratio">
						<img :data-src="jewelryItem.thumbnail" class="lazy-image" />
					</div>
				</router-link>
			</div>

			<!-- Description -->
			<div class="col p-3">
				<div class="text-center1">
					<!-- Title -->
					<div class="fs-5" v-html="jewelryItem.title"></div>
					<!-- Article -->
					<div class="text-uppercase font-monospace font-weight-light small" style="opacity: 0.5;">
						{{ jewelryItem.slug }}
						<i class="fas fa-calculator me-1" v-if="jewelryItem.calc" :class="!jewelryItem.calcTrue ? 'text-danger' : ''"></i>
						<i class="fas fa-cube" v-if="jewelryItem.embeddedF360"></i>
					</div>
					<!-- Description -->
					<div v-if="jewelryItem.description" class="mt-2 small" v-html="jewelryItem.description" style="white-space: pre-wrap;"></div>
				</div>
			</div>

			<div class="col-3 p-3 text-end">
				<!-- Price -->
				<div class="my-1 fs-6 text-danger">
					{{ numberToRUB((jewelryItem.price / (100 + +jewelryItem.extraPercent)) * 100) }}
				</div>
				<div class="my-1 fs-6 text-success">
					+ {{ numberToRUB(jewelryItem.price - (jewelryItem.price / (100 + +jewelryItem.extraPercent)) * 100) }}
				</div>
				<div class="my-1 fs-5">
					{{ numberToRUB(jewelryItem.price) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { numberToRUB } from '@/scripts/filters'

export default defineComponent({
	name: 'JewelryPriceItem',
	props: { jewelryItem: Object },
	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
	},
})
</script>

<style lang="scss" scoped>
a {
	text-decoration: none !important;
	color: inherit !important;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
</style>
