import { VuexModule, Module, Action, Mutation, MutationAction } from 'vuex-module-decorators'

@Module({
	name: 'AppModule',
})
export class AppModule extends VuexModule {
	// showSidebar = true
	jewelryItemEdit = false
	jewelryItemEditTab = 'basic-info-tab'

	// @Action
	// toggleSidebar() {
	// 	this.TOGGLE_SIDEBAR(!this.showSidebar)
	// }

	// @Mutation
	// private TOGGLE_SIDEBAR(showSidebar: boolean) {
	// 	this.showSidebar = showSidebar
	// }

	@Action
	toggleJewelryItemEdit() {
		this.TOGGLE_JEWELRY_ITEM_EDIT(!this.jewelryItemEdit)
	}

	@Mutation
	private TOGGLE_JEWELRY_ITEM_EDIT(jewelryItemEdit: boolean) {
		this.jewelryItemEdit = jewelryItemEdit
	}

	@MutationAction({ mutate: ['jewelryItemEditTab'] })
	async updateJewelryItemEditTab(jewelryItemEditTab: string) {
		return { jewelryItemEditTab }
	}

	get editTab() {
		return this.jewelryItemEditTab ? this.jewelryItemEditTab : 'basic-info-tab'
	}
}
