import { OrdersCompleteness, OrdersSort } from '@/models/orders.models'
import { VuexModule, Module, MutationAction, Action } from 'vuex-module-decorators'

@Module({
	name: 'OrdersFilterModule',
})
export class OrdersFilterModule extends VuexModule {
	sort = OrdersSort.EndDate
	completeness = OrdersCompleteness.Incomplete

	@MutationAction({ mutate: ['sort'] })
	async updateSort(sort: OrdersSort) {
		return { sort }
	}

	@MutationAction({ mutate: ['completeness'] })
	async updateCompleteness(completeness: OrdersCompleteness) {
		return { completeness }
	}

	@Action
	resetFilter() {
		this.updateSort(OrdersSort.EndDate)
		this.updateCompleteness(OrdersCompleteness.Incomplete)
	}

	get ordersFilterChanged() {
		return this.sort === OrdersSort.EndDate && this.completeness === OrdersCompleteness.Incomplete ? false : true
	}
}
