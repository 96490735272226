import { createApp } from 'vue'
import { store } from '@/store'
import { router } from '@/router'
import * as bootstrap from 'bootstrap'

import App from '@/App.vue'

import '@/assets/style.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'cropperjs/dist/cropper.min.css'

const main = createApp(App)
main.use(store)
main.use(router)
main.config.globalProperties.$bootstrap = bootstrap
main.mount('#app')
