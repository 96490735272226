<template>
	<div class="toast show d-flex align-items-center border-0" :class="toastClass">
		<div class="toast-body" v-html="message"></div>
		<button type="button" class="btn-close btn-close-white ms-auto me-2" @click="removeToast"></button>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Toasts } from '@/store'
import { ToastVariants } from '@/models/toasts.models'

export default defineComponent({
	name: 'Toast',
	props: { id: Number, message: String, variant: String, autohide: Boolean },

	data() {
		return {
			remove: false,
		}
	},

	mounted() {
		if (this.autohide) setTimeout(() => this.removeToast(), 5000)
	},

	computed: {
		toastClass() {
			return {
				'text-white bg-success': this.variant === ToastVariants.success,
				'text-white bg-primary': this.variant === ToastVariants.primary,
				'text-black bg-info': this.variant === ToastVariants.info,
				'text-black bg-warning': this.variant === ToastVariants.warning,
				'text-white bg-danger': this.variant === ToastVariants.danger,
				'toast-in': !this.remove,
				'toast-out': this.remove,
			}
		},
	},

	methods: {
		removeToast() {
			this.remove = true
			setTimeout(() => Toasts.removeToast(this.id), 500)
		},
	},
})
</script>

<style>
.toast-in {
	transform-origin: center top;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	animation-name: fadeZoomIn;
}
.toast-out {
	transform-origin: center top;
	transition-duration: 0.5s;
	transition-property: transform, opacity;
	animation-timing-function: ease-in-out;
	opacity: 0;
	transform: translateX(-100px);
}
@keyframes fadeZoomIn {
	from {
		opacity: 0;
		transform: translateX(-100px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}
</style>
