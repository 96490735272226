
import { defineComponent } from 'vue'
import { Server } from '@/store'

export default defineComponent({
	name: 'HeaderLoading',

	computed: {
		loading() {
			return Server.loading
		},
	},
})
