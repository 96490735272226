import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'

@Module({
	name: 'ServerModule',
})
export class ServerModule extends VuexModule {
	loading = 0

	@Action({ commit: 'CHANGE_LOADING' })
	addLoading() {
		// console.warn('[Server module] Add loading')
		return 1
	}
	@Action({ commit: 'CHANGE_LOADING' })
	removeLoading() {
		// console.warn('[Server module] Remove loading')
		return -1
	}

	@Mutation
	CHANGE_LOADING(loading: number) {
		this.loading += loading
	}
}
