import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { User } from '@/store'

import Catalog from '@/views/catalog.view.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'LogIn',
		component: () => import('@/views/login.view.vue'),
		meta: { title: 'ICHIEN | Вход' },
	},
	{
		path: '/',
		name: 'Catalog',
		component: Catalog,
	},
	{
		path: '/jewelry/:id',
		name: 'SingleJewelry',
		component: () => import('@/views/single-jewelry.view.vue'),
		meta: { title: 'ICHIEN | ' },
	},
	{
		path: '/orders',
		name: 'Orders',
		component: () => import('@/views/orders.view.vue'),
		meta: { title: 'ICHIEN | Заказы' },
	},
]

export const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve(savedPosition)
				}, 300)
			})
		} else {
			return { top: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	document.title = (to.meta.title as string) || 'ICHIEN'

	if (to.name !== 'LogIn' && !User.loggedIn) next({ name: 'LogIn' })
	else if (to.name === 'LogIn' && User.loggedIn) next({ name: 'Catalog' })
	else next()
})
